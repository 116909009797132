import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src", "title"]
const _hoisted_2 = { key: 0 }

import { computed } from 'vue'
import { getImageAsset } from '@/web/client/utils/getImageAsset'


export default /*@__PURE__*/_defineComponent({
  __name: 'RandomSubaru',
  props: {
    isGolden: { type: Boolean },
    showMessage: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const image = computed(() => {
    if (props.isGolden) {
        return getImageAsset('golden-subaru.png')
    }

    return getImageAsset('subaru.png')
})

const message = computed(() => {
    if (props.isGolden) {
        return 'You have been blessed by the golden Subaru'
    }

    return 'You have been visited by Subaru'
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      src: image.value,
      title: message.value,
      width: "200"
    }, null, 8, _hoisted_1),
    (_ctx.showMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(message.value), 1))
      : _createCommentVNode("", true)
  ], 64))
}
}

})